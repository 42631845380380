<template>
  <section class="mx-auto px-4 max-w-6xl match-to-scroll-to">
    <h3 class="text-center block mb-4 mt-10 text-base text-brandGrey">
      {{ date }}
    </h3>

    <div class="space-y-4 md:space-y-0 md:flex flex-wrap justify-center">
      <Card
        v-for="(match, index) in matches"
        :key="index"
        :index="index"
        :match="match"
        :gameCount="gameCount"
        :competition="competition"
        :oddsType="oddsType"
        :mode="mode"
      />
    </div>
  </section>
</template>

<script>
import Card from "../components/Card";
export default {
  name: "MatchDay",
  props: ["date", "matches", "mode", "competition", "oddsType", "index"],
  components: {
    Card,
  },
  computed: {
    gameCount() {
      return this.matches.length;
    },
  },
};
</script>
